import React from 'react'
import Img from 'gatsby-image'

import './testimonialsBlock.css'
import Sparks from '../graphic/sparks'
import VisibilityWrapper from '../../helper/visibilityWrapper'
import SplitText from 'react-pose-text'
import { animations } from '../../helper/variables'

const TestimonialsBlock = props => {
  const { page } = props

  const ariseAnimation = animations('ARISE')

  return (
    <div className="testimonial-block-container content-container">
      <Sparks type="3" className="testimonial-spark" />
      <VisibilityWrapper partialVisibility={true}>
        {({ isVisible }) => {
          return (
            <div
              className={`key-image image-container ${isVisible &&
                'show'} no-hover`}
            >
              <div className="image-wrapper">
                <Img fluid={page.testimonialKeyImage.fluid} />
              </div>
            </div>
          )
        }}
      </VisibilityWrapper>
      <div className="content-block">
        <VisibilityWrapper partialVisibility={true}>
          {({ isVisible }) => {
            return (
              <h2 className="type-h1 testimonial-title">
                <SplitText
                  initialPose="exit"
                  pose={isVisible ? 'enter' : 'exit'}
                  withParent={false}
                  charPoses={ariseAnimation}
                >
                  {page.testimonialTitle}
                </SplitText>
              </h2>
            )
          }}
        </VisibilityWrapper>
        <ul className="list">
          {page.testimonials.map(testimonial => (
            <VisibilityWrapper key={testimonial.id} partialVisibility={true}>
              {({ isVisible }) => {
                return (
                  <li className="list-item">
                    <div
                      className={`logo-container image-container ${isVisible &&
                        'show'}`}
                    >
                      <div className="image-wrapper">
                        <Img fluid={testimonial.logo.fluid} />
                      </div>
                    </div>
                    <div
                      className={`quote-container animation animation-${isVisible &&
                        'appear'}`}
                    >
                      <p>{testimonial.testimonial.testimonial}</p>
                      <div className="by type-sub3">{testimonial.by}</div>
                    </div>
                  </li>
                )
              }}
            </VisibilityWrapper>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default TestimonialsBlock
