import React from 'react'

import './valuesBlock.css'
import Sparks from '../graphic/sparks'
import Parallax from '../../helper/parallax'
import { MobileView } from 'react-device-detect'

const ValuesBlock = props => {
  const { page, momentumScrollValue } = props

  return (
    <div className="values-block-container ">
      <div className="content-container">
        <div className="value-spark">
          <Parallax
            viewportScroll={momentumScrollValue}
            moveRange="200"
            disabledOnMobile
          >
            <Sparks type="3" />
          </Parallax>
        </div>
        <MobileView>
          <h2 className="type-h1 mobile-values-title">{page.valuesTitle}</h2>
        </MobileView>

        <ul className="values-list">
          {page.values.map(value => (
            <li key={value.id}>
              <h3 className="type-h7 value">{value.title}</h3>
              <div className="value-description">
                {value.description.description}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ValuesBlock
