import React, { Component } from 'react'
import Layout from '../layout/layout'
import Helmet from 'react-helmet'
import ScrollManager from '../helper/scrollManager'
import { easeFunction } from '../helper/variables'
import posed from 'react-pose'
import { connect } from 'react-redux'
import HeroBlock from '../components/serviceBlocks/heroBlock'
import HeadImage from '../components/common/headImage'

import './about.css'
import ValuesBlock from '../components/aboutBlocks/valuesBlock'
import ValuesBg from '../components/aboutBlocks/valuesBG'
import TestimonialsBlock from '../components/aboutBlocks/testimonialsBlock'
import { isBrowser, BrowserView } from 'react-device-detect'
import AwardsBlock from '../components/aboutBlocks/awardsBlock'
import StudioBlock from '../components/aboutBlocks/studioBlock'
import Footer from '../components/common/footer'
import { graphql } from 'gatsby'
import VisibilityWrapper from '../helper/visibilityWrapper'
import IntroBlock from '../components/aboutBlocks/introBlock'
import CTA from '../components/common/cta'
import StatementBlock from '../components/aboutBlocks/statementBlock'
import Circle from '../components/graphic/circle'
import SectionIndicator from '../components/common/sectionIndicator'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class About extends Component {
  state = {
    mainNode: null,
    scrollValue: 0,
    momentumScrollValue: 0,
    windowHeight: 0,
    onValues: false,
    triggerPointIntro: 0,
    triggerPointValues: 0,
    triggerPointTestimonial: 0,
    triggerPointAwards: 0,
    triggerPointStudio: 0,
    triggerPointCTA: 0,
    triggerPointFooter: 0,
    isReady: false,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
    this.sectionIntro = React.createRef()
    this.sectionValues = React.createRef()
    this.sectionTestimonial = React.createRef()
    this.sectionAwards = React.createRef()
    this.sectionStudio = React.createRef()
    this.sectionCTA = React.createRef()
    this.sectionFooter = React.createRef()
  }

  componentDidMount() {
ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.getTriggerPoints)

    this.getTriggerPoints()

    this.setState({
      mainNode: this.mainNode.current,
      isReady: true,
    })

    this.props.hideSymbol()
    this.props.backgroundWhite()
    this.props.noHomepageLoading()
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.getTriggerPoints)

    this.props.backgroundWhite()
  }

  getTriggerPoints = () => {
    const windowHeight = typeof window !== 'undefined' && window.innerHeight
    const { momentumScrollValue } = this.state

    this.setState({
      windowHeight,
      triggerPointCTA:
        momentumScrollValue +
        this.sectionCTA.current.getBoundingClientRect().top,
      triggerPointIntro:
        momentumScrollValue +
        this.sectionIntro.current.getBoundingClientRect().top,
      triggerPointValues:
        momentumScrollValue +
        this.sectionValues.current.getBoundingClientRect().top,
      triggerPointTestimonial:
        momentumScrollValue +
        this.sectionTestimonial.current.getBoundingClientRect().top,
      triggerPointAwards:
        momentumScrollValue +
        this.sectionAwards.current.getBoundingClientRect().top,
      triggerPointStudio:
        momentumScrollValue +
        this.sectionStudio.current.getBoundingClientRect().top,
      triggerPointFooter:
        momentumScrollValue +
        this.sectionFooter.current.getBoundingClientRect().top,
    })

    return windowHeight
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })

    if (value > this.state.triggerPointFooter - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else if (value > this.state.triggerPointCTA) {
      this.props.backgroundRed()
    } else if (value > this.state.triggerPointStudio - (isBrowser ? 75 : 55)) {
      this.props.backgroundWhite()
    } else if (value > this.state.triggerPointIntro - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else if (value > this.state.triggerPointIntro - 200) {
      this.props.backgroundWhite()
    } else if (
      value >
      this.state.triggerPointTestimonial - (isBrowser ? 75 : 55)
    ) {
      this.props.backgroundWhite()
    } else if (value > this.state.triggerPointTestimonial - 400) {
      this.props.backgroundRed()
      this.setState({
        onValues: false,
      })
    } else if (value > this.state.triggerPointAwards - (isBrowser ? 75 : 55)) {
      this.props.backgroundRed()
    } else if (
      value >
      this.state.triggerPointAwards - (isBrowser ? 800 : 400)
    ) {
      this.props.backgroundWhite()
      this.setState({
        onValues: false,
      })
    } else if (value > this.state.triggerPointValues - (isBrowser ? 75 : 55)) {
      this.props.backgroundWhite()
      this.setState({
        onValues: true,
      })
    } else if (value > this.state.triggerPointValues - 300) {
      this.setState({
        onValues: true,
      })
    } else {
      this.setState({
        onValues: false,
      })
    }
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  render() {
    const page = this.props.data.contentfulAbout
    const {
      momentumScrollValue,
      onValues,
      triggerPointValues,
      isReady,
    } = this.state
    const { theme } = this.props

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{page.metaData.metaTitle}</title>
          <meta name="description" content={page.metaData.description} />
          <meta name="keywords" content={page.metaData.keywords} />
          <meta property="og:title" content={page.metaData.metaTitle} />
          <meta property="og:description" content={page.metaData.description} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              page.metaData.ogImage
                ? `https:${page.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta property="og:url" content="https://www.baunfire.com/about" />
          <link rel="canonical" href="https://www.baunfire.com/about" />
          <script>
            {`  if (typeof fbq === 'undefined') {  
            !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '588053714946302');
  fbq('track', 'PageView');
fbq('track', 'FindLocation');
  }else {
    fbq('track', 'PageView');
    fbq('track', 'FindLocation'); 
  }
`}
          </script>
        </Helmet>
        <div style={{ backgroundColor: '#f9f9f9' }}>
          <PageTransition>
            <ScrollManager
              page={this.state.mainNode}
              scrollValue={this.state.scrollValue}
              updateScrollValue={this.updateScrollValue}
              updateMomentumScrollValue={this.updateMomentumScrollValue}
            >
              <Circle theme={theme} />
              <section className="page-about">
                <div className="bg-white">
                  <HeroBlock
                    page={page}
                    momentumScrollValue={momentumScrollValue}
                  />
                  <HeadImage
                    fluid={page.headImage.fluid}
                    momentumScrollValue={momentumScrollValue}
                  />
                </div>

                <section className="section-statement">
                  <div className="statement-circle">
                    <Circle theme="black" />
                  </div>
                  <StatementBlock
                    data={page}
                    momentumScrollValue={momentumScrollValue}
                  />
                </section>

                <section className="section-values" ref={this.sectionValues}>
                  <ValuesBlock
                    page={page}
                    momentumScrollValue={momentumScrollValue}
                  />
                </section>

                <div className="bg-white">
                  <VisibilityWrapper partialVisibility={true}>
                    {({ isVisible }) => {
                      return (
                        <section
                          className={`section-awards ${isVisible && 'appear'}`}
                          ref={this.sectionAwards}
                        >
                          <AwardsBlock page={page} />
                        </section>
                      )
                    }}
                  </VisibilityWrapper>
                  <section
                    className="section-customers"
                    ref={this.sectionTestimonial}
                  >
                    <TestimonialsBlock
                      page={page}
                      momentumScrollValue={momentumScrollValue}
                    />
                  </section>

                  <VisibilityWrapper partialVisibility={true}>
                    {({ isVisible }) => {
                      return (
                        <div
                          className={`area-about-intro ${isVisible &&
                            'appear'}`}
                        >
                          <div className="content-container">
                            <section
                              className="section-about-intro section-block"
                              ref={this.sectionIntro}
                            >
                              <IntroBlock
                                data={page}
                                momentumScrollValue={momentumScrollValue}
                              />
                            </section>
                          </div>
                        </div>
                      )
                    }}
                  </VisibilityWrapper>

                  <section className="section-studio" ref={this.sectionStudio}>
                    <StudioBlock page={page} theme={theme} />
                  </section>

                  <VisibilityWrapper partialVisibility={true}>
                    {({ isVisible }) => {
                      return (
                        <div className={`area-cta ${isVisible && 'appear'}`}>
                          <div className="content-container">
                            <section
                              className="section-cta"
                              ref={this.sectionCTA}
                            >
                              <CTA momentumScrollValue={momentumScrollValue} />
                            </section>
                          </div>
                        </div>
                      )
                    }}
                  </VisibilityWrapper>
                </div>
              </section>
              <section ref={this.sectionFooter}>
                <Footer />
              </section>
            </ScrollManager>
            <BrowserView>
              <ValuesBg
                page={page}
                momentumScrollValue={momentumScrollValue}
                onValues={onValues}
                startingPoint={triggerPointValues}
                isReady={isReady}
              />

              <SectionIndicator theme={theme}>
                {page.heroRibbonText}
              </SectionIndicator>
            </BrowserView>
          </PageTransition>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query($page: String = "About") {
    contentfulAbout(title: { eq: $page }) {
      metaData {
        title
        metaTitle
        description
        keywords
      }
      heroRibbonText
      heroHeadline
      heroDescription
      headImage {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      statementRibbonText
      statementText
      introRibbonText
      introText
      valuesTitle
      values {
        id
        title
        description {
          description
        }
      }
      testimonialTitle
      testimonialKeyImage {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      testimonials {
        id
        title
        testimonial {
          testimonial
        }
        by
        logo {
          fluid(maxWidth: 240, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      awardsTitle
      awardsLogos {
        id
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      studioTitle
      studioDescription
      studioImages {
        id
        fluid(maxWidth: 900, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    hideSymbol: () => dispatch({ type: 'SHOW_LOGO' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedAbout = connect(
  mapStateToProps,
  mapDispatchToProps
)(About)

export default ConnectedAbout
