import React from 'react'
import Sparks from '../graphic/sparks'
import Img from 'gatsby-image'

import './awardsBlock.css'

const AwardsBlock = props => {
  const { page } = props

  return (
    <div className="awards-block-container content-container">
      <Sparks type="3" className="awards-spark" />
      <div>
        <h2 className={`type-h1 color-white`}>{page.awardsTitle}</h2>
        <ul className="award-list">
          {page.awardsLogos.map(award => (
            <li key={award.id}>
              <div className="logo-container">
                <Img fluid={award.fluid} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default AwardsBlock
